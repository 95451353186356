import type { Transaction } from '~/types/transaction'

// Reusable composable
const { api } = useApi()

const loading = ref({
  transactions: false,
  moreTransactions: false,
  error: false,
})
const currentPage = ref(2)
const perPage = 30
const totalPage = ref(0)
const totalTransactions = ref(0)
const isTransactionsFinished = ref(false)

export default function useTransaction() {
  // Reusable composables
  const { user } = storeToRefs(useUserStore())
  const { transactions } = storeToRefs(useTransactionStore())
  const { logError } = useSentry()

  const currentTransaction = ref<Transaction | null>(null)

  const recentTransactions = computed(() => {
    return transactions.value?.slice(0, 5) as Transaction[]
  })

  async function fetchTransactions() {
    try {
      loading.value.transactions = true
      loading.value.error = false
      const response = await api.transaction.fetchTransactions({
        page: 1,
        userId: user.value?.id,
      })
      if (response) {
        transactions.value = response.data
        totalPage.value = response.totalPage
        totalTransactions.value = response.total
      }
      loading.value.transactions = false
    } catch (err) {
      loading.value.transactions = false
      loading.value.error = true
      logError(err)
    }
  }
  async function fetchNextTransactions() {
    if (currentPage.value <= totalPage.value) {
      try {
        loading.value.moreTransactions = true
        const response = await api.transaction.fetchTransactions({
          page: currentPage.value,
          userId: user.value?.id,
        })
        loading.value.moreTransactions = false
        if (response) {
          currentPage.value++
          transactions.value?.push(...response.data)
        }
      } catch (err) {
        loading.value.moreTransactions = false
        logError(err)
      }
    } else {
      isTransactionsFinished.value = true
    }
  }

  return {
    loading,
    perPage,
    totalTransactions,
    isTransactionsFinished,
    currentTransaction,
    recentTransactions,
    fetchTransactions,
    fetchNextTransactions,
  }
}
